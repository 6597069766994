import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import theme from '../../utils/themeconstants';
import StageContainer from '../../components/elements/stageContentContainer';

const BackgroundContainer = styled.div`
  height: 100vh;
  position: relative;
  width: 100%;
  background-size: cover;
`;

const BackgroundImage = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100vh;
`;

const StageTitle = styled.div`
  height: 100vh;
  display:flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: ${theme.space.lg}px;
  h1,h2, h3 {
    color: white;
  }
  h2 {
    font-weight: lighter;
  }
`;


const StageClimate = () => (
  <StaticQuery
    query={graphql`
    query {
      bgImg: file(relativePath: { eq: "climatestage-background1.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }`}
    render={data => (
      <BackgroundContainer>
        <BackgroundImage fluid={data.bgImg.childImageSharp.fluid} />
        <StageContainer style={{ position: 'absolute', top: 0 }}>
          <StageTitle>
            <h1>Climate Change</h1>
            <h2>a pragmatic guide</h2>
          </StageTitle>
        </StageContainer>
      </BackgroundContainer>
    )}
  />
);


export default StageClimate;
