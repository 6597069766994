import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
  max-Width: 960px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;
`;


const stageContentContainer = ({ children, style }) => (
  <Container style={style}>
    {children}
  </Container>
);

stageContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default stageContentContainer;
